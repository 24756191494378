import * as React from 'react'
import { graphql } from 'gatsby'
import CHRSectionContainer from './../components/sectionContainer'
import { Typography } from '@material-ui/core'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/aboutStyles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import SEO from '../components/seo'
import CHRFloatImageRight from '../components/floatImagRight'
import CHRFloatImageLeft from '../components/floatImageLeft'
import { videos } from '../constants'

interface AboutPageProps {
  t: TFunction
  data: any
}

const AboutPage = ({ t, data }: AboutPageProps) => {
  const classes = styles()
  return (
    <Layout>
      <SEO
        title={t('about.metaTitle')}
        description={t('company.metaDescription')}
      />
      <div className={classes.headingTextContainer}>
        <Typography variant="h2">{t('about.greetings')}</Typography>
        <Typography variant="h4" component="h1">
          {t('about.welcome')}
          <br />
          {t('about.findYourVoice')}
        </Typography>
      </div>
      <CHRSectionContainer>
        <section className={classes.aboutSection}>
          <CHRFloatImageLeft
            rightChildren={
              <div>
                {' '}
                <Typography variant="h2">{t('about.ourMission')}</Typography>
                <Typography variant="subtitle1" component="p">
                  {t('about.toCreateAShoppingPlatform')}
                </Typography>
              </div>
            }
            isGatsbyImg={true}
            image={data.allImageSharp.edges[1].node.fluid}
            withoutButton
          />
        </section>
      </CHRSectionContainer>
      <CHRSectionContainer>
        <section>
          <CHRFloatImageRight
            withoutButton="true"
            leftChildren={
              <div>
                {' '}
                <Typography variant="h2">
                  {t('about.howItWorks')}
                </Typography>{' '}
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.listItem}
                >
                  {t('about.signUp')}
                </Typography>{' '}
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.listItem}
                >
                  {t('about.addOurBrowserExtension')}
                </Typography>{' '}
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.listItem}
                >
                  {t('about.addProductsFromSites')}
                </Typography>{' '}
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.listItem}
                >
                  {t('about.earnCashBackWhenPeopleBuyThem')}
                </Typography>
              </div>
            }
            video={videos.extensionVideo4}
            isMediaContained
            withoutButton
          />
        </section>
      </CHRSectionContainer>
      <CHRSectionContainer>
        <section className={classes.aboutSection}>
          <CHRFloatImageLeft
            rightChildren={
              <div>
                {' '}
                <Typography variant="h2">{t('about.ourFounder')}</Typography>
                <Typography variant="subtitle1" component="p">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('about.coletteSheltonFoundedChirpyest', {
                        href: 'http://cococozy.com/',
                      }),
                    }}
                  />
                </Typography>
              </div>
            }
            isGatsbyImg={true}
            image={data.allImageSharp.edges[0].node.fluid}
            withoutButton
          />
        </section>
      </CHRSectionContainer>
    </Layout>
  )
}

export default withTranslation()(AboutPage)

export const query = graphql`
  query {
    allImageSharp(
      filter: {
        fluid: {
          originalName: { in: ["about-our-mission.jpg", "about-founder.png"] }
        }
      }
      sort: { order: ASC, fields: fluid___originalName }
    ) {
      edges {
        node {
          id
          fluid(maxHeight: 1024) {
            src
            srcSet
            base64
            aspectRatio
            originalImg
            originalName
            sizes
          }
        }
      }
    }
  }
`
